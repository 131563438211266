import React from 'react';
import { Instagram } from './iconSvg';

const Productoras = () => {
    return (
        <section className="ld--wrap-prods">
            <div className="ld--card">
                <h5>Produce</h5>
                <ul style={{ marginTop: '12px', maxHeight: `186px`, overflow: 'auto' }} className='px-0'>
                    <li className="produtore_list">
                        <div style={{ overflow: 'hidden', borderRadius: '50%' }}>
                            <img src={`/images/totalshow.jpg`} alt="Total Show" />
                        </div>
                        <div>
                            <span className="span_productor">Total Show</span>
                            <a href="https://www.instagram.com/totalshowve/?hl=es" rel="noreferrer" target="_blank" style={{ color: `var(--color__text)`, cursor: 'pointer' }}>
                                <Instagram />
                                @totalshowve
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default Productoras;
